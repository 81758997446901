import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import config from '../../config';
export default function Footer() {
  const data = useStaticQuery(graphql`
    query {
      sanityContact {
        title
        text
        address
        facebook
        linkedin
        twitter
        mail
        tel
      }
    }
  `);

  const content = data.sanityContact;

  return (
    <section id="kontakt">
      <div className="inner">
        <h2 className="major">{content.title}</h2>
        <p>{content.text}</p>
        <ul className="contact" style={{ paddingTop: 40 }}>
          <li className="fa-home">
            <a
              target="_blank"
              href="https://www.google.com/maps/place/Kreuzbuchstrasse+36,+6045+Meggen/data=!4m2!3m1!1s0x478ff938d97f002f:0x427ae0282488d10?sa=X&ved=2ahUKEwjwtdr_-qjqAhVEJhoKHXeZDSEQ8gEwAHoECAsQAQ"
            >
              {content.address}
            </a>
          </li>
          <li className="fa-phone">
            <a href={'tel://' + content.tel}>{content.tel}</a>
          </li>
          {content.linkedin && (
            <li className="fa-linkedin">
              <a href={content.linkedin}>{content.linkedin}</a>
            </li>
          )}
          {content.facebook && (
            <li className="fa-facebook">
              <a href={content.facebook}>{content.facebook}</a>
            </li>
          )}
          {content.twitter && (
            <li className="fa-twitter">
              <a href={content.twitter}>{content.twitter}</a>
            </li>
          )}
          {content.instagram && (
            <li className="fa-instagram">
              <a href={content.instagram}>{content.instagram}</a>
            </li>
          )}
          <li className="fa-envelope">
            <a href={'mailto:' + content.mail}>{content.mail}</a>
          </li>
        </ul>
        <ul className="copyright">
          <li>&copy; Milian Schenk. All rights reserved.</li>
          <li>
            <Link to={'/impressum-datenschutz'}>Impressum & Datenschutz</Link>
          </li>
        </ul>
      </div>
    </section>
  );
}
