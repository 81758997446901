module.exports = {
  siteTitle: 'Nur durch Mässigung erhalten wir uns.', // <title>
  manifestName: 'MillianSchenk',
  manifestShortName: 'MS', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/images/portrait.jpg',
  pathPrefix: `/`,
  heading: 'Nur durch Mässigung erhalten wir uns.',
  subHeading: 'Arbeiten Sie in einem Unternehmen',
};
