import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';

export default function Nav({ sanityMenu, onMenuToggle = () => {} }) {
  const data = useStaticQuery(graphql`
    query {
      sanityMenu {
        menuPages {
          title
          slug {
            current
          }
        }
      }
    }
  `);
  const menu = data && data.sanityMenu.menuPages;

  return (
    <nav id="menu">
      <div className="inner">
        <h2>Menu</h2>
        <ul className="links">
          <li>
            <Link
              onClick={e => {
                onMenuToggle();
              }}
              to="/"
            >
              Home
            </Link>
          </li>

          {menu &&
            menu.map((m, i) => (
              <li key={i}>
                <Link
                  onClick={e => {
                    onMenuToggle();
                  }}
                  to={'/' + m.slug.current}
                >
                  {m.title}
                </Link>
              </li>
            ))}
          <li>
            <Link
              onClick={e => {
                onMenuToggle();
              }}
              to="/blog"
            >
              Blog
            </Link>
          </li>
          <li>
            <Link
              onClick={e => {
                onMenuToggle();
              }}
              to="#kontakt"
            >
              Kontakt
            </Link>
          </li>
        </ul>
        <a
          className="close"
          onClick={e => {
            e.preventDefault();
            onMenuToggle();
          }}
          href="#menu"
        >
          {''}
        </a>
      </div>
    </nav>
  );
}
